import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Fade from 'react-reveal'

import Layout from '../components/layout'
import Head from '../components/head'
import ListItem from '../components/listItem'
import styles from './tools.module.scss'

const ToolsPage = () => {
    const { allContentfulApp } = useStaticQuery(graphql`
        query {
            allContentfulApp(sort: {
                fields: [publishedDate]
                order: [DESC]
            }) {
                nodes {
                    id
                    title
                    publishedDate(formatString: "DD-MM-YYYY")
                    linkToPlay
                    description
                    previewImage {
                        title
                        fluid(maxWidth: 250) {
                            ...GatsbyContentfulFluid
                        }
                    }
                    isSmall
                }
            }
        }
    `)
    const apps = allContentfulApp.nodes

    return (
        <Layout>
            <Head title="Tools" />
            <p>Here are some of the tools and apps I wrote. They open in a new tab.</p>
            <div className={styles.apps}>
                { 
                    apps.map((app, index) => {
                        return (
                            <Fade left key={index} duration={500} distance={'50%'} >
                            <ListItem key={index} {...app} isSmallImages={app.isSmall} />
                            </Fade>
                        )
                    })
                }
            </div>
        </Layout>
    )
}

export default ToolsPage